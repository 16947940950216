import React, { useState } from "react"
import { Link } from "gatsby"
import Slider from "react-slick";
import parse from "html-react-parser"
import { Container, Row, Col, Form } from "react-bootstrap"
import Img01 from "../../images/Property/property01.png"
import Img001 from "../../images/Property/property001.png"
import Img002 from "../../images/Property/property002.png"
import ImgDesk01 from "../../images/Property/desk-property01.png"
import ImgDesk02 from "../../images/Property/desk-property002.png"
import ImgDesk03 from "../../images/Property/desk-property003.png"
import NoImage from "../../images/pittis-no-image.png"

import ScrollAnimation from 'react-animate-on-scroll';
import "../PropertyDetails/Property/Property.scss"
import { inViewOptions, containerVariants, contentItem, fadeInFromTop } from '../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const settings = {
    speed: 800,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    centerMode: false,
    responsive: [
        {
            breakpoint: 3020,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,


            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,

            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                dots: true,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1,
                dots: true,

            }
        }
    ]
};
const SuccessStory = (props) => {
    const [tabActive, setIsActive] = useState(false)
    const tabbing1 = () => setIsActive(false);
    const tabbing2 = () => setIsActive(true);
    const [iconOne, setOne] = useState(false);
    const [iconTwo, setTwo] = useState(false);
    const [iconThree, setThree] = useState(false);

    const handleFirst = () => {
        setOne(!iconOne);
    }

    const handleTwo = () => {
        setTwo(!iconTwo);
    }

    const handleThree = () => {
        setThree(!iconThree);
    }
    console.log('branch', props.success_stories.success_flyer)
    return (
        <InView  {...inViewOptions}>
            {({ ref, inView }) => (
                <motion.section
                    className="property success-stories property-stories-top"
                    ref={ref}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    variants={containerVariants}

                >
                    <Container>
                        <Row>
                            <Col >
                                <motion.div variants={contentItem} className="title d-none d-lg-block">
                                    {props.branch_name !== "Newport" ? <span className="sm-heading">{props.branch_name} success stories</span> : <h4 className="sm-heading">Newport success stories</h4>}
                                    <h2>Helping you move forwards on your life’s journey.</h2>
                                </motion.div>
                                <Slider {...settings} >

                                    {props.success_stories.success_flyer && props.success_stories.success_flyer.map((item, i) => {
                                        return (
                                            <motion.div variants={contentItem} className="property-card-list">
                                                <div className="properties-wrapper ">
                                                    <div className="property-img img-zoom">
                                                        <div class="sold-tag">
                                                            Sold <br /><span>stc</span></div>
                                                        <picture>
                                                            <source media="(min-width:992px)" srcset={item.srcUrl ? item.srcUrl : NoImage} />
                                                            <source media="(min-width:768px)" srcset={item.srcUrl ? item.srcUrl : NoImage} />
                                                            <img src={item.srcUrl ? item.srcUrl : NoImage} alt="img" />
                                                        </picture>
                                                    </div>

                                                    <div className="properties-info">
                                                        <h2 className="heading-info d-flex justify-content-between" >
                                                            {item.location}
                                                        </h2>
                                                        <ul className="free-text">
                                                            {item.free_text_1 && <li>{parse(item.free_text_1)}</li>}
                                                            {item.free_text_2 && <li>{parse(item.free_text_2)}</li>}
                                                            {item.free_text_3 && <li>{parse(item.free_text_3)}</li>}
                                                        </ul>

                                                        {(!item.free_text_1) && (!item.free_text_2) && (!item.free_text_3) &&
                                                            <ul className="free-text">
                                                                <li>This property was on the market with another agent for 150 days!</li>
                                                                <li>With our great marketing strategy it has NOW SOLD stc!</li>
                                                            </ul>
                                                        }
                                                        <span className="info-text">Info accurate as at {item._created} Ref: {item.propref}</span>
                                                    </div>
                                                </div>
                                            </motion.div>
                                        )
                                    }
                                    )}

                                </Slider>

                                <div className="success-bottom-text">Properties referred to as "Sold" were either "Sold" or "Sold Subject to Contract" (STC) at the time of producing the success story.</div>

                            </Col>
                        </Row>
                    </Container>
                </motion.section>
            )}
        </InView>
    )
}
export default SuccessStory
