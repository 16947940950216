import React, { useEffect, useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import parse from 'html-react-parser';
import axios from 'axios';
import Img from 'gatsby-image';
import GenerateLink from "../common/site/generate-link"

import { useWindowSize } from '../../hooks/winodw-size'
import "../AreaGuideDetails/About/About.scss"
import NoImage from "../../images/pittis-no-image.png"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../utils/animation';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const About = (props) => {
    const [windowWidth] = useWindowSize();
    const [propItems, setPropItems] = useState(false)
    const getitems = async url => {
        try {
            const { data } = await axios.get(url, {
                headers: {
                    Authorization:
                        `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
                },
            })
            setPropItems(data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        // let area = props.propertyData.area ?? "";
        let url = process.env.GATSBY_STRAPI_SRC + `/area-guides?Name_contains=${props.name}`; // TODO: base URL supposed to be from .env
        // if (area) {
        //     url = url + "&display_address_contains=" + area;
        // }

        getitems(url)
    }, [])
    return (
        <React.Fragment>
            {propItems.length > 0 ?
                <InView  {...inViewOptions}>
                    {({ ref, inView }) => (
                        <motion.section
                            className="about-area area-guide-section components--AreaGuideDetails--About--About"
                            ref={ref}
                            initial="hidden"
                            animate={inView ? "visible" : "hidden"}
                            variants={contentItemStagger}

                        >
                            {propItems && propItems.map((item, index) => {
                                return (
                                    <Container>
                                        <Row>
                                            <div className="row-box flex-lg-row-reverse">
                                                <Col md="12" lg="6" className="pe-lg-0">
                                                    <motion.div variants={contentItem} className="img-zoom about-block-img">
                                                        {item.Tile_Image ?
                                                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} /> :
                                                            <img src={NoImage} alt={item.Name} />
                                                        }

                                                    </motion.div>
                                                </Col>
                                                <Col md="12" lg="6">
                                                    <div className="about-content">
                                                        {props.name !== "Newport" ? <span className="sub-title">
                                                            <motion.div variants={contentItem}>
                                                                {props.name} Area Guide
                                                            </motion.div>
                                                        </span> : <h4 className="sub-title">Newport Area Guide</h4>}
                                                        <motion.div variants={contentItem}>
                                                            {item.Add_Content &&
                                                                <>
                                                                    {parse((item.Add_Content[0].Add_Content.split("</p>")[0] + '</p>'))}
                                                                </>
                                                            }
                                                            {/* {parse(props.Content)} */}
                                                        </motion.div>
                                                        <motion.div variants={contentItem}>
                                                            <Link to={`/about-pittis-estate-agents/area-guide/${item.URL}`} class="btn">View Area Guide
                                                                <i className="icon-arrow"></i>
                                                            </Link>
                                                        </motion.div>
                                                    </div>
                                                </Col>
                                            </div>
                                        </Row>


                                    </Container>
                                )
                            })}

                        </motion.section>
                    )}
                </InView> : ''
            }
        </React.Fragment>
    )
}
export default About
