import { Link } from "gatsby"
import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, Container, Row, Col, Form } from "react-bootstrap"
import GenerateLink from "../../common/site/generate-link"

// import deskbanner from "../../../images/offplan/desk-banner.png";
import "./BookValuation.scss"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
// markup
const BranchBookValuationFooter = (props) => {

  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        officeDetailsModule {
            Market_your_property_CTA_Label
            Market_your_property_Content
            Market_your_property_Video_URL
            News_Section_Intro
            Footer_Help_Module {
              CTA_1_Label
              CTA_2_Label
              Content
              Name
              Show_Chart
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Select_Form {
                Select
              }
            }
            Market_your_property_CTA_Link {
              id
            }
            Select_Help_Module_to_Show {
              CTA_1_Label
              CTA_2_Label
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Content
              Name
              Show_Chart
              Select_Form {
                Select
              }
            }
            Services_Links {
              Services_Background_Title
              Add_Service_Tile {
                Title
                Link {
                  id
                }
                Tile_Background_Image {
                  url
                  alternativeText 
                }
              }
            }
            Market_your_property_Image {
              url
              alternativeText
            }
            About_Wards_CTA_Label
            About_Wards_CTA_Link {
              id
            }
          }
  
      }
    }
    `);


  const GlobalModule = data.glstrapi.officeDetailsModule;
  return (
    <React.Fragment>
      {GlobalModule.Footer_Help_Module ?
        <InView  {...inViewOptions}>
          {({ ref, inView }) => (
            <motion.section
              className="book-valuation components--Home--BookValuation--BookValuation"
              ref={ref}
              initial="hidden"
              animate={inView ? "visible" : "hidden"}
              variants={contentItemStagger}

            >
              <Container>
                <Row className="main-row">
                  <Col lg="12">
                    <div className="register-interest-form-block">
                      <motion.div key={1} custom={1} variants={fadeInFromTop} className="valuation-block">
                        {GlobalModule.Footer_Help_Module?.Content &&
                          <>
                            {parse(GlobalModule.Footer_Help_Module.Content)}
                          </>
                        }
                      </motion.div>
                      <div className="btn-row">

                        {GlobalModule.Footer_Help_Module?.CTA_1_Label && GlobalModule.Footer_Help_Module.CTA_1_Link &&

                          <motion.div key={2} custom={2} variants={fadeInFromTop}>
                            <GenerateLink link={GlobalModule.Footer_Help_Module.CTA_1_Link} class="btn btn-secondary">
                              {GlobalModule.Footer_Help_Module.CTA_1_Label}<i className="icon-arrow"></i>
                            </GenerateLink>
                          </motion.div>
                        }

                        {GlobalModule.Footer_Help_Module?.CTA_2_Label && GlobalModule.Footer_Help_Module.CTA_2_Link &&
                          <motion.div key={3} custom={3} variants={fadeInFromTop}>
                            <GenerateLink link={GlobalModule.Footer_Help_Module.CTA_2_Link} class="btn btn-secondary">
                              {GlobalModule.Footer_Help_Module.CTA_2_Label}<i className="icon-arrow"></i>
                            </GenerateLink>
                          </motion.div>
                        }

                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </motion.section>
          )}
        </InView> : ''}
    </React.Fragment>
  )
}

export default BranchBookValuationFooter
