import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"
import VirtualTourPlay from "../../Play/CustomVideoFullImageClick"

import ContactModal from "../../ModalForm/Contact"
import "./About.scss"

import { inViewOptions, containerVariants, contentItem, fadeInFromTop, titleVariants, contentVariants } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const BranchAbout = (props) => {
  const [show, setShow] = useState(false);
  const showMenu = () => {
    setShow(!show);
  };

  return (
    <InView  {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.section
          className={`about-section components--Home--About--About branch-intro`}
          ref={ref}
          initial="visible"
          animate={"visible"}
          variants={containerVariants}

        >
          <Container>
            <Row>
              <Col md="12">
                <motion.div variants={titleVariants} className="about-block-title">
                  {props.Intro_Title &&
                    <span className="sub-title">{props.Intro_Title}</span>
                  }

                  {props.Intro_Heading &&
                    <h2>
                      {props.Intro_Heading}
                    </h2>
                  }
                </motion.div>
              </Col>
              <Col md="12" className="d-lg-flex ">
                <motion.div variants={contentVariants} className="about-block">
                  {/* {props.Intro_Content &&
                    <div className="main-content">
                      {parse(props.Intro_Content)}
                    </div>
                  } */}

                  <div className={`${show ? " " : "para-content"} ${(props.Intro_Content).length > 100 ? 'height' : 'no-height'}`}  > 
                    
                      {parse(props.Intro_Content)} 
                  </div>
                  {(props.Intro_Content).length > 100 &&
                    <div className={show ? "more-content fixed" : "more-content"} onClick={showMenu} >
                      <span onClick={showMenu}>
                        <i className={show ? "icon-minus-dark " : "icon-plus "} ></i>
                        <Link className="more">
                          {show ? "Read Less" : "Read More"}
                        </Link>
                      </span>
                    </div>
                  }

                  {props.Intro_CTA_Label && props.Intro_CTA_Link &&
                    <Link to={props.Intro_CTA_Link} class="btn btn-about-link"><span>{props.Intro_CTA_Label}</span> <i className="icon-arrow"></i></Link>
                  }
                </motion.div>
              </Col>
            </Row>
          </Container>
        </motion.section>
      )}
    </InView>

  )
}
export default BranchAbout
