import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Container } from 'react-bootstrap';
import Img from 'gatsby-image';
import parse from 'html-react-parser';
import loadable from '@loadable/component'
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../../predictive-search/SearchResultBox"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../utils/property-search"
// import GoogleRating from "../../googleRating/GoogleRating"
import Enquiry from "../../Branches/BranchEnquiry"
// 
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import GenerateLink from "../../common/site/generate-link"
import TrustPilotWidget from "../../TrustPilotWidget"
import GoogleReviewCount from "../../GoogleReviewCount/GoogleReviewCount"

import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
import Close from "../../../images/home-close.svg"



// styles
import ScrollAnimation from 'react-animate-on-scroll';
import "./Banner.scss"
// markup

import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const LoqateAddress = loadable(() => import('../../forms/elements/address-search-instant'))

const Banner = (props) => {

  const image_url = props.image[0]?.url;

  const banner_image = props.Banner_Image?.url

  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.image_Transforms) {
    processedImages = props.imagetransforms.image_Transforms;
  }

  var address = ''
  if (props.address.building) {
    address = props.address.building + ', '
  }
  if (props.address.street) {
    address += props.address.street + ', '
  }
  if (props.address.town) {
    address += props.address.town + ', '
  }
  if (props.address.district) {
    address += props.address.district + ', '
  }
  if (props.address.postcode) {
    address += props.address.postcode
  }


  const [bannercta, setBannerCTA] = useState(true);
  const [valuation, setValuation] = useState(false);
  const [propertysearch, setPropertySearch] = useState(false);
  const [areaVal, setAreaVal] = useState('')
  const queryList = QueryList();

  function OpenValuation() {
    setBannerCTA(false)
    setValuation(true)
    setPropertySearch(false)

  }

  function OpenPropertySearch() {
    setBannerCTA(false)
    setValuation(false)
    setPropertySearch(true)

  }

  function CloseSearch() {
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)

  }


  // Valuation
  function onClickBuy(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();

    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    localStorage.setItem('hometrack_add', hometrack_add)

    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)

    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)

    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }

  function onClickRent(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();
    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    localStorage.setItem('hometrack_add', hometrack_add)
    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)
    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)
    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }
  // Valuation


  // Property Search 

  function onClickPropertyBuy(val) {
    let url = "/property/for-sale/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-blackwater-newport-isle-of-wight/radius-8-miles/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'blackwater-newport-isle-of-wight');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'blackwater-newport-isle-of-wight');

    navigate(url);
    // window.location.href = url;
  }
  function onClickPropertyRent(val) {
    let url = "/property/to-rent/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-blackwater-newport-isle-of-wight/radius-8-miles/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'blackwater-newport-isle-of-wight');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'blackwater-newport-isle-of-wight');

    navigate(url);
    // window.location.href = url;
  }
  // Property Search


  useEffect(() => {
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)
  }, []);


  return (

    <React.Fragment>
      <div className="main-banner branch-detail-banner components--Home--Banner-Banner">
        <div className="main-banner-embed">
          {banner_image ?
            <img src={banner_image} alt={`${props.branch_name} - Pittis`} /> :
            image_url ?
              <img src={image_url} alt={`${props.branch_name} - Pittis`} /> :
              <img src={props.image[0]?.srcUrl} alt={`${props.branch_name} - Pittis`} />
          }
        </div>
        <div className="main-banner-content">
          <Container>
            <InView  {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.div
                  className="banner-text"
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={contentItemStagger}

                >

                  <motion.div key={1} custom={1} variants={fadeInFromTop}>
                    {props.branch_name !== "Newport" ? <h1>Pittis {props.address?.town ? props.address?.town : props.branch_name}</h1> : <h1>Pittis Newport Estate Agents</h1> }
                    {props.branch_name == "Fine & Country Canterbury" ?
                    <h2>Canterbury's local estate agent</h2> : props.branch_name == "Medway Lettings" ?
                    <h2>Medway's local lettings agent</h2> : props.branch_name === "Newport" ? <h2>Your local lettings & estate agents in Newport</h2> :
                    <h2>{props.branch_name}'s local estate agent</h2> }
                  </motion.div>

                  {props.type === 'branch' &&
                    <div className="banner-contact-info">
                      <div className="phone">
                        <i className="branch-phone"></i> <span>{props.sales_dept?.phone && <>Sales: <a className="tel" href={`tel:${props.sales_dept?.phone}`}>{props.sales_dept?.phone}</a></>}
                          {props.sales_dept?.phone && props.lettings_dept?.phone && <> /</>} {props.lettings_dept?.phone && <>Lettings: <a className="tel" href={`tel:${props.lettings_dept?.phone}`}>{props.lettings_dept?.phone}</a></>}</span>
                      </div>
                      <div className="contact">
                        <i className="branch-contact"></i>
                        <Enquiry name='' class="details-banner-mail" branch={'Branch Contact'}> Contact Branch </Enquiry>
                      </div>
                      {props.address &&
                        <div className="address">
                          <i className="branch-address"></i> <span>{address}</span>
                        </div>
                      }
                    </div>
                  }


                  <div className="home-banner-search" id="home-banner-search">
                    {bannercta &&
                      <div className="btn-wrap" id="home-banner-search-cta">
                        <motion.div key={2} custom={2} variants={fadeInFromTop} className="btn-width-cta">
                          <a href="javascript:void(0)" class="btn btn-secondary" onClick={() => OpenValuation()}>
                            <span>Selling or letting?</span>
                            Book a valuation
                          </a>
                        </motion.div>
                        <motion.div key={3} custom={3} variants={fadeInFromTop} className="btn-width-cta">
                          <a href="javascript:void(0)" class="btn" onClick={() => OpenPropertySearch()}>
                            <span>Buying or renting?</span>
                            Find a property
                          </a>
                        </motion.div>
                      </div>
                    }
                    {valuation &&
                      <ScrollAnimation animateIn="fadeInUp">
                        <div className="home-banner-search-valuation" id="home-banner-search-valuation">
                          <LoqateAddress />
                          <div className="btn-wrap">
                            <button class="btn btn-secondary" onClick={() => onClickBuy()}>
                              <span>I want to</span>Sell
                            </button>
                            <button class="btn btn-primary" onClick={() => onClickRent()}>
                              <span>I want to</span>Let
                            </button>
                            <img src={Close} className="close" alt="" onClick={() => CloseSearch()} />
                          </div>
                        </div>
                      </ScrollAnimation>
                    }
                    {propertysearch &&
                      <ScrollAnimation animateIn="fadeInUp">
                        <div className="home-banner-search-property" id="home-banner-search-property">
                          <div class="text form-group form-group">
                            <div class="custom-float">

                              <SearchResultInputBox
                                value={areaVal !== 'blackwater-newport-isle-of-wight' ? hyphenToCaptilize(areaVal) : ''}
                                setValue={(searchValue) => {
                                  // refine(removeSpecialChar(searchValue))
                                  setAreaVal(searchValue)
                                }}
                                placeHolder={`Enter town or area`}
                                areaList={queryList.areaList}
                                additionalclass="address address-search"
                              />
                            </div>
                          </div>
                          <div className="btn-wrap">
                            <button class="btn btn-secondary" onClick={() => onClickPropertyBuy(areaVal)}>
                              <span>I’d like to</span>Buy
                            </button>
                            <button class="btn" onClick={() => onClickPropertyRent(areaVal)}>
                              <span>I’d like to</span>Rent
                            </button>
                            <img src={Close} alt="" onClick={() => CloseSearch()} />
                          </div>

                        </div>
                      </ScrollAnimation>
                    }
                  </div>

                </motion.div>
              )}
            </InView>

            {/* <InView  {...inViewOptions}>
              {({ ref, inView }) => (
                <motion.div
                  className="rating-block"
                  ref={ref}
                  initial="hidden"
                  animate={inView ? "visible" : "hidden"}
                  variants={contentItemStagger}

                >
                  <motion.div variants={contentItem} className="trustpilot-review">
                    <TrustPilotWidget />
                  </motion.div>

                  <div className="land-banner-review">
                  <motion.div variants={contentItem} className="google-review">
                    <GoogleReviewCount />
                  </motion.div>
                  </div>
                </motion.div>
              )}
            </InView> */}
          </Container>
        </div>


      </div>
    </React.Fragment>
  )
}

export default Banner