import React, { useState, useRef, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { Row, Col, Container, Button } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../../common/site/generate-link"

import "./GetKnow.scss";

import { inViewOptions1, containerVariants, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

const BranchGetKnow = (props) => {

    const data = useStaticQuery(graphql`
    query {
      glstrapi {
        officeDetailsModule {
            Market_your_property_CTA_Label
            Market_your_property_Content
            Market_your_property_Video_URL
            News_Section_Intro
            Footer_Help_Module {
              CTA_1_Label
              CTA_2_Label
              Content
              Name
              Show_Chart
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Select_Form {
                Select
              }
            }
            Market_your_property_CTA_Link {
              id
            }
            Select_Help_Module_to_Show {
              CTA_1_Label
              CTA_2_Label
              CTA_1_Link {
                id
              }
              CTA_2_Link {
                id
              }
              Content
              Name
              Show_Chart
              Select_Form {
                Select
              }
            }
            Services_Links {
              Services_Background_Title
              Add_Service_Tile {
                Title
                Link {
                  id
                }
                Tile_Background_Image {
                  url
                  alternativeText
                  url_sharp {
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO,WEBP]
                        width: 350
                        height: 320
                        quality: 90
                        layout: CONSTRAINED
                        transformOptions: { cropFocus: CENTER, fit: COVER }
                      )  
                    }
                  } 
                }
              }
            }
            Market_your_property_Image {
              url
              alternativeText 
            }
            About_Wards_CTA_Label
            About_Wards_CTA_Link {
              id
            }
          }
  
      }
    }
    `);
  

    const module = data.glstrapi.officeDetailsModule;

  return (
    <InView  {...inViewOptions1}>
      {({ ref, inView }) => (
        <motion.div
          className="other-areas-block components--Home-GetKnow--GetKnow"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}

        >
          <section className={`scroll-text-bg ${module.Services_Links.Services_Background_Title.replace(/ /g, "-").replace(/&/g, "").replace("?", "").replace(/--/g, "-").toLowerCase()}`}>
            <Container>
              <Row>
                <Col lg={12}>
                  {/* {module.Services_Links.Services_Background_Title &&
                    <motion.div variants={fadeInFromTop} className="block-heading">

                      <h2>{module.Services_Links.Services_Background_Title}</h2>

                    </motion.div>
                  } */}
                  <div className="other-areas-list">
                    <motion.div variants={fadeInFromTop}  >
                      <Row>
                        {module.Services_Links.Add_Service_Tile && module.Services_Links.Add_Service_Tile.map((item, index) => (
                          <>
                            {item.Title && item.Link &&
                              <Col lg={3} md={6}>
                                <GenerateLink link={item.Link} class="get-know-link">
                                  <div key={index} className="other-areas-item img-zoom">
                                    <div className="other-areas-img">
                                      {item.Tile_Background_Image &&
                                        <GatsbyImage image={getImage(item.Tile_Background_Image.url_sharp)} alt={item.Tile_Background_Image.alternativeText} loading="lazy"/>
                                        // <Img fluid={item.Tile_Background_Image.url_sharp.childImageSharp.fluid} alt={item.Tile_Background_Image.alternativeText} />
                                      }

                                    </div>
                                    <div class="btn btn-white">{item.Title} <i className="icon-arrow"></i></div>

                                  </div>
                                </GenerateLink>
                              </Col>
                            }</>

                        ))}
                      </Row>
                    </motion.div>
                  </div>
                  {module.Services_Links.Services_Content &&
                  <div className="bottom-content">
                    {parse(module.Services_Links.Services_Content)}
                  </div>
                  }
                </Col>
              </Row>
            </Container>
          </section>
        </motion.div>
      )}
    </InView>

  );
};

export default BranchGetKnow;
